import React, { useState, useEffect } from "react";
import StateOptions from "./AdminComponents/StateOptions";
import CityOptions from "./AdminComponents/CityOptions";
const moment = require("moment");
const BASE_URL = process.env.REACT_APP_BASE_LINK;

function Student(props) {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [Name, setName] = useState("");
  const [DateOfBirth, setDateOfBirth] = useState("");
  const [CourseName, setCourseName] = useState("");
  const [Gender, setGender] = useState("Male");
  const [PhoneNumber1, setPhoneNumber1] = useState("");
  const [PhoneNumber2, setPhoneNumber2] = useState("");
  const [Email, setEmail] = useState("");
  const [AadharNo, setAadharNo] = useState("");
  const [FatherName, setFatherName] = useState("");
  const [MotherName, setMotherName] = useState("");
  const [MaritalStatus, setMaritalStatus] = useState("Single");
  const [Batch, setBatch] = useState("");
  const [Address, setAddress] = useState("");
  const [ZipCode, setZipCode] = useState("");
  const [State, setState] = useState("Delhi");
  const [City, setCity] = useState("New Delhi");
  const [Qualification, setQualification] = useState("1 to 5");
  const [SchoolCollegeName, setSchoolCollegeName] = useState("");
  const [BoardUniversityName, setBoardUniversityName] = useState("");
  const [Reference, setReference] = useState("Digital Marketing");
  const [StudentImage, setStudentImage] = useState("");
  const [StudentSignature, setStudentSignature] = useState("");
  const [InitialFees, setInitialFees] = useState("");
  const [RegistrationFees, setRegistrationFees] = useState("");
  const [Status, setStatus] = useState("Active");
  const [BatchList, setBatchList] = useState([]);
  const [StudentData, setStudentData] = useState([]);
  const [EnquiryData, setEnquiryData] = useState(props.enquiryData);

  const handleName = (e) => {
    setName(e.target.value);
  };

  const handleDateOfBirth = (e) => {
    setDateOfBirth(e.target.value);
  };

  const handleCourseName = (e) => {
    setCourseName(e.target.value);
  };

  const handleGender = (e) => {
    setGender(e.target.value);
  };

  const handlePhoneNumber1 = (e) => {
    setPhoneNumber1(e.target.value);
  };

  const handlePhoneNumber2 = (e) => {
    setPhoneNumber2(e.target.value);
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleAadharNo = (e) => {
    setAadharNo(e.target.value);
  };

  const handleFatherName = (e) => {
    setFatherName(e.target.value);
  };

  const handleMotherName = (e) => {
    setMotherName(e.target.value);
  };

  const handleMaritalStatus = (e) => {
    setMaritalStatus(e.target.value);
  };

  const handleBatch = (e) => {
    setBatch(e.target.value);
  };

  const handleAddress = (e) => {
    setAddress(e.target.value);
  };

  const handleZipCode = (e) => {
    setZipCode(e.target.value);
  };

  const handleState = (selectedState) => {
    setState(selectedState);
  };

  const handleCity = (e) => {
    setCity(e.target.value);
  };

  const handleQualification = (e) => {
    setQualification(e.target.value);
  };

  const handleSchoolCollegeName = (e) => {
    setSchoolCollegeName(e.target.value);
  };

  const handleBoardUniversityName = (e) => {
    setBoardUniversityName(e.target.value);
  };

  const handleReference = (e) => {
    setReference(e.target.value);
  };

  const handleStudentImage = async (e) => {
    const file = e.target.files[0];

    if (!file) return;

    if (file.size <= 100000) {
      // If the image is smaller than 100KB, set the student image state with the original image Data URL
      const reader = new FileReader();
      reader.onload = () => {
        setStudentImage(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      try {
        const reader = new FileReader();
        reader.onload = async (event) => {
          const image = new Image();
          image.src = event.target.result;

          image.onload = () => {
            const canvas = document.createElement("canvas");
            let width = image.width;
            let height = image.height;

            const maxWidth = 500;
            const maxHeight = 500;
            const maxSizeBytes = 1000000; // 1MB

            if (width > maxWidth) {
              height *= maxWidth / width;
              width = maxWidth;
            }

            if (height > maxHeight) {
              width *= maxHeight / height;
              height = maxHeight;
            }

            // Set the canvas dimensions to the new compressed image size
            canvas.width = width;
            canvas.height = height;

            const ctx = canvas.getContext("2d");
            ctx.drawImage(image, 0, 0, width, height);

            // Get the compressed image as a Blob
            canvas.toBlob(
              async (blob) => {
                let compressedImageDataURL;
                if (blob.size <= maxSizeBytes) {
                  // If the compressed image is <= 1MB, send it as is
                  compressedImageDataURL = canvas.toDataURL("image/jpeg", 1);
                  setStudentImage(compressedImageDataURL);
                } else {
                  // If the compressed image is still > 1MB, try reducing the quality iteratively
                  let quality = 0.9;
                  while (blob.size > maxSizeBytes && quality >= 0.1) {
                    compressedImageDataURL = canvas.toDataURL(
                      "image/jpeg",
                      quality
                    );

                    // Get the new Blob size for the current compressed data URL
                    blob = await fetch(compressedImageDataURL).then((res) =>
                      res.blob()
                    );

                    quality -= 0.1;
                  }
                  setStudentImage(compressedImageDataURL);
                }
              },
              "image/jpeg",
              1
            );
          };
        };

        reader.readAsDataURL(file);
      } catch (error) {
        console.error("Error compressing image:", error);
      }
    }
  };

  const handleStudentSignature = async (e) => {
    const file = e.target.files[0];

    if (!file) return;

    if (file.size <= 100000) {
      // If the image is smaller than 100KB, set the student signature state with the original image Data URL
      const reader = new FileReader();
      reader.onload = () => {
        setStudentSignature(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      try {
        const reader = new FileReader();
        reader.onload = async (event) => {
          const image = new Image();
          image.src = event.target.result;

          image.onload = () => {
            const canvas = document.createElement("canvas");
            let width = image.width;
            let height = image.height;

            const maxWidth = 500;
            const maxHeight = 500;
            const maxSizeBytes = 1000000; // 1MB

            if (width > maxWidth) {
              height *= maxWidth / width;
              width = maxWidth;
            }

            if (height > maxHeight) {
              width *= maxHeight / height;
              height = maxHeight;
            }

            // Set the canvas dimensions to the new compressed image size
            canvas.width = width;
            canvas.height = height;

            const ctx = canvas.getContext("2d");
            ctx.drawImage(image, 0, 0, width, height);

            // Get the compressed image as a Blob
            canvas.toBlob(
              async (blob) => {
                let compressedImageDataURL;
                if (blob.size <= maxSizeBytes) {
                  // If the compressed image is <= 1MB, send it as is
                  compressedImageDataURL = canvas.toDataURL("image/jpeg", 1);
                  setStudentSignature(compressedImageDataURL);
                } else {
                  // If the compressed image is still > 1MB, try reducing the quality iteratively
                  let quality = 0.9;
                  while (blob.size > maxSizeBytes && quality >= 0.1) {
                    compressedImageDataURL = canvas.toDataURL(
                      "image/jpeg",
                      quality
                    );

                    // Get the new Blob size for the current compressed data URL
                    blob = await fetch(compressedImageDataURL).then((res) =>
                      res.blob()
                    );

                    quality -= 0.1;
                  }
                  setStudentSignature(compressedImageDataURL);
                }
              },
              "image/jpeg",
              1
            );
          };
        };

        reader.readAsDataURL(file);
      } catch (error) {
        console.error("Error compressing image:", error);
      }
    }
  };

  const handleInitialFees = (e) => {
    setInitialFees(e.target.value);
  };

  const handleRegistrationFees = (e) => {
    setRegistrationFees(e.target.value);
  };

  const handleStatus = (e) => {
    setStatus(e.target.value);
  };

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const fetchBatchData = () => {
    fetch(BASE_URL + "getbatches", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data.existingBatch)) {
          setBatch(data.existingBatch[0].BatchCode); // Set the default batch value to the first
          setBatchList(data.existingBatch); // Store the batch data in state
        } else {
          console.error("Error featching the data. Please try again!");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const feildValidator = () => {
    if (Name === "") {
      alert("Please Enter Name");
      return false;
    } else if (DateOfBirth === "") {
      alert("Please Enter Date Of Birth");
      return false;
    } else if (CourseName === "") {
      alert("Please Enter Course Name");
      return false;
    } else if (Gender === "") {
      alert("Please Enter Gender");
      return false;
    } else if (PhoneNumber1 === "") {
      alert("Please Enter Phone Number");
      return false;
    } else if (Email === "") {
      alert("Please Enter Email");
      return false;
    } else if (AadharNo === "") {
      alert("Please Enter Aadhar Number");
      return false;
    } else if (FatherName === "") {
      alert("Please Enter Father's Name");
      return false;
    } else if (MotherName === "") {
      alert("Please Enter Mother's Name");
      return false;
    } else if (MaritalStatus === "") {
      alert("Please Enter Marital Status");
      return false;
    } else if (Batch === "") {
      alert("Please Enter Batch");
      return false;
    } else if (Address === "") {
      alert("Please Enter Address");
      return false;
    } else if (ZipCode === "") {
      alert("Please Enter ZipCode");
      return false;
    } else if (State === "") {
      alert("Please Enter State");
      return false;
    } else if (City === "") {
      alert("Please Enter City");
      return false;
    } else if (Qualification === "") {
      alert("Please Enter Qualification");
      return false;
    } else if (SchoolCollegeName === "") {
      alert("Please Enter School/College Name");
      return false;
    } else if (BoardUniversityName === "") {
      alert("Please Enter Board/University Name");
      return false;
    } else if (Reference === "") {
      alert("Please Enter Reference");
      return false;
    } else if (InitialFees === "") {
      alert("Please Enter Initial Fees");
      return false;
    } else if (RegistrationFees === "") {
      alert("Please Enter Registration Fees");
      return false;
    } else if (Status === "") {
      alert("Please Enter Status");
      return false;
    } else {
      if (PhoneNumber1.length < 10) {
        alert("Please Enter Valid Phone Number");
        return false;
      }
      return true;
    }
  };

  const clearAllData = () => {
    setName("");
    setDateOfBirth("");
    setCourseName("");
    setGender("Male");
    setPhoneNumber1("");
    setPhoneNumber2("");
    setEmail("");
    setAadharNo("");
    setFatherName("");
    setMotherName("");
    setMaritalStatus("Single");
    setBatch("");
    setAddress("");
    setZipCode("");
    setState("Delhi");
    setCity("New Delhi");
    setQualification("1 to 5");
    setSchoolCollegeName("");
    setBoardUniversityName("");
    setReference("Digital Marketing");
    setStudentImage("");
    setStudentSignature("");
    setInitialFees("");
    setRegistrationFees("");
    setStatus("Active");
  };

  const fetchStudents = () => {
    fetch(BASE_URL + "getstudents?key=Status&value=Active&limit=5", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data.students)) {
          setStudentData(data.students); // Store the batch data in state
        } else {
          console.error("Error featching the data. Please try again!");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const AddStudent = () => {
    if (feildValidator()) {
      const requestBody = {
        Name: Name,
        DateOfBirth: DateOfBirth,
        CourseName: CourseName,
        Gender: Gender,
        PhoneNumber1: PhoneNumber1,
        PhoneNumber2: PhoneNumber2,
        Email: Email,
        AadharNo: AadharNo,
        FatherName: FatherName,
        MotherName: MotherName,
        MaritalStatus: MaritalStatus,
        Batch: Batch,
        Address: Address,
        ZipCode: ZipCode,
        State: State,
        City: City,
        Qualification: Qualification,
        SchoolCollegeName: SchoolCollegeName,
        BoardUniversityName: BoardUniversityName,
        Reference: Reference,
        StudentImage: StudentImage,
        StudentSignature: StudentSignature,
        InitialFees: InitialFees,
        RegistrationFees: RegistrationFees,
        Status: Status,
      };

      fetch(BASE_URL + "addstudent", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      })
        .then((response) => response.json())
        .then((data) => {
          // Handle the response data
          if (data["message"] !== "Addition successful") {
            alert(data["message"]);
            fetchStudents();
          } else {
            alert("Addition Successful");
            togglePopup();
            clearAllData();
            fetchStudents();
          }
          // Perform any necessary actions based on the response
        })
        .catch((error) => {
          // Handle any errors and print them to the console
          console.error("Error:", error);
        });
    }
  };

  const DeleteStudent = (Name, FatherName, PhoneNumber) => {
    const shouldDelete = window.confirm(
      "Are you sure you want to delete this student?"
    );

    if (!shouldDelete) {
      return; // User clicked on "Cancel" or closed the dialog
    }

    const requestBody = {
      Name,
      FatherName,
      PhoneNumber,
    };

    fetch(BASE_URL + "deletestudent", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the response data
        if (data["message"]) {
          alert(data["message"]);
          fetchStudents();
        } else {
          alert("No response from the server. Please try again!");
          fetchStudents();
        }
        // Perform any necessary actions based on the response
      })
      .catch((error) => {
        // Handle any errors and print them to the console
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    fetchStudents();
  }, []);

  useEffect(() => {
    fetchBatchData();
  }, []);

  useEffect(() => {
    if (EnquiryData !== null) {
      setName(EnquiryData.Name);
      setDateOfBirth(moment(EnquiryData.DateOfBirth).format("YYYY-DD-MM"));
      setCourseName(EnquiryData.CourseName);
      setGender(EnquiryData.Gender);
      setPhoneNumber1(EnquiryData.PhoneNumber);
      setEmail(EnquiryData.Email);
      setFatherName(EnquiryData.FatherName);
      setMotherName(EnquiryData.MotherName);
      setAddress(EnquiryData.Address);
      setZipCode(EnquiryData.ZipCode);
      setState(EnquiryData.State);
      setCity(EnquiryData.City);
      setQualification(EnquiryData.Qualification);
      setReference(EnquiryData.Reference);
      setStatus(EnquiryData.Status);
      setIsPopupOpen(true);
    }
  }, []);

  return (
    <div>
      <button
        type="button"
        className="btn btn-primary btn-lg"
        style={{
          borderRadius: "0%",
          paddingLeft: "25px",
          paddingRight: "25px",
          marginTop: "6px",
          marginBottom: "0.5px",
        }}
        onClick={togglePopup}
      >
        Add Student
      </button>

      <div
        style={{
          border: "1px solid black",
          overflow: "auto",
          width: "115%",
          maxHeight: "90vh",
        }}
      >
        {isPopupOpen && (
          <div style={{ padding: "10px" }}>
            <i
              className="fa-solid fa-xmark"
              onClick={togglePopup}
              style={{ float: "right", fontSize: "22px", padding: "2px" }}
            ></i>
            <h3 style={{ textAlign: "center", paddingBottom: "20px" }}>
              Add Student
            </h3>

            <div className="container">
              <div className="row py-5">
                <div className="col-6">
                  <p style={{ fontSize: "12px", color: "gray" }}>Name*</p>
                  <input
                    className="AddBatchBox"
                    type="text"
                    placeholder="Name*"
                    value={Name}
                    onChange={handleName}
                  />
                </div>

                <div className="col-6">
                  <p style={{ fontSize: "12px", color: "gray" }}>
                    Date Of Birth*
                  </p>
                  <input
                    className="AddBatchBox"
                    type="date"
                    placeholder="Date Of Birth*"
                    value={DateOfBirth}
                    onChange={handleDateOfBirth}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-6">
                  <p style={{ fontSize: "12px", color: "gray" }}>
                    Course Name*
                  </p>
                  <input
                    className="AddBatchBox"
                    type="text"
                    placeholder="Course Name*"
                    value={CourseName}
                    onChange={handleCourseName}
                  />
                </div>

                <div className="col-6">
                  <p style={{ fontSize: "12px", color: "gray" }}>Gender*</p>
                  <select
                    className="AddBatchBox"
                    value={Gender}
                    onChange={handleGender}
                  >
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
              </div>

              <div className="row py-5">
                <div className="col-6">
                  <p style={{ fontSize: "12px", color: "gray" }}>
                    Phone Number 1*
                  </p>
                  <input
                    className="AddBatchBox"
                    type="number"
                    placeholder="Phone Number 1*"
                    value={PhoneNumber1}
                    onChange={handlePhoneNumber1}
                  />
                </div>

                <div className="col-6">
                  <p style={{ fontSize: "12px", color: "gray" }}>
                    Phone Number 2
                  </p>
                  <input
                    className="AddBatchBox"
                    type="number"
                    placeholder="Phone Number 2"
                    value={PhoneNumber2}
                    onChange={handlePhoneNumber2}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-6">
                  <p style={{ fontSize: "12px", color: "gray" }}>Email*</p>
                  <input
                    className="AddBatchBox"
                    type="mail"
                    placeholder="Email*"
                    value={Email}
                    onChange={handleEmail}
                  />
                </div>

                <div className="col-6">
                  <p style={{ fontSize: "12px", color: "gray" }}>
                    Aadhar Number*
                  </p>
                  <input
                    className="AddBatchBox"
                    type="number"
                    placeholder="Aadhar Number*"
                    value={AadharNo}
                    onChange={handleAadharNo}
                  />
                </div>
              </div>

              <div className="row py-5">
                <div className="col-6">
                  <p style={{ fontSize: "12px", color: "gray" }}>
                    Father's Name*
                  </p>
                  <input
                    className="AddBatchBox"
                    type="text"
                    placeholder="Father's Name*"
                    value={FatherName}
                    onChange={handleFatherName}
                  />
                </div>

                <div className="col-6">
                  <p style={{ fontSize: "12px", color: "gray" }}>
                    Mother's Name*
                  </p>
                  <input
                    className="AddBatchBox"
                    type="text"
                    placeholder="Mother's Name*"
                    value={MotherName}
                    onChange={handleMotherName}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-6">
                  <p style={{ fontSize: "12px", color: "gray" }}>
                    Marital Status*
                  </p>
                  <select
                    className="AddBatchBox"
                    value={MaritalStatus}
                    onChange={handleMaritalStatus}
                  >
                    <option value="Single">Single</option>
                    <option value="Married">Married</option>
                    <option value="Prefer Not To Say">Prefer Not To Say</option>
                  </select>
                </div>

                <div className="col-6">
                  <p style={{ fontSize: "12px", color: "gray" }}>Batch*</p>
                  <select
                    className="AddBatchBox"
                    value={Batch}
                    onChange={handleBatch}
                  >
                    {BatchList.map((batch) => (
                      <option value={batch.BatchCode}>
                        {batch.BatchName +
                          " ( " +
                          batch.BatchStartTime +
                          " To " +
                          batch.BatchEndTime +
                          " )"}
                      </option>
                    ))}
                    <option value="Other">Other</option>
                  </select>
                </div>
              </div>

              <div className="row py-5">
                <div className="col-6">
                  <p style={{ fontSize: "12px", color: "gray" }}>Address*</p>
                  <input
                    className="AddBatchBox"
                    type="text"
                    placeholder="Addresse*"
                    value={Address}
                    onChange={handleAddress}
                  />
                </div>

                <div className="col-6">
                  <p style={{ fontSize: "12px", color: "gray" }}>Zip Code*</p>
                  <input
                    className="AddBatchBox"
                    type="number"
                    placeholder="Zip Code*"
                    value={ZipCode}
                    onChange={handleZipCode}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-6">
                  <p style={{ fontSize: "12px", color: "gray" }}>State*</p>
                  <StateOptions State={State} handleStateChange={handleState} />
                </div>

                <div className="col-6">
                  <p style={{ fontSize: "12px", color: "gray" }}>City*</p>
                  <CityOptions City={City} handleCityChange={handleCity} />
                </div>
              </div>

              <div className="row py-5">
                <div className="col-6">
                  <p style={{ fontSize: "12px", color: "gray" }}>
                    Qualification*
                  </p>
                  <select
                    className="AddBatchBox"
                    value={Qualification}
                    onChange={handleQualification}
                  >
                    <option value="1 to 5">1 to 5</option>
                    <option value="6 to 10">6 to 10</option>
                    <option value="11 to 12">11 to 12</option>
                    <option value="Graduation">Graduation</option>
                    <option value="Post Graduation">Post Graduation</option>
                  </select>
                </div>

                <div className="col-6">
                  <p style={{ fontSize: "12px", color: "gray" }}>
                    School/College Name*
                  </p>
                  <input
                    className="AddBatchBox"
                    type="text"
                    placeholder="School/College Name*"
                    value={SchoolCollegeName}
                    onChange={handleSchoolCollegeName}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-6">
                  <p style={{ fontSize: "12px", color: "gray" }}>
                    Board/University Name*
                  </p>
                  <input
                    className="AddBatchBox"
                    type="text"
                    placeholder="Board/University Name*"
                    value={BoardUniversityName}
                    onChange={handleBoardUniversityName}
                  />
                </div>

                <div className="col-6">
                  <p style={{ fontSize: "12px", color: "gray" }}>Reference*</p>
                  <select
                    className="AddBatchBox"
                    value={Reference}
                    onChange={handleReference}
                  >
                    <option value="Digital Marketing">Digital Marketing</option>
                    <option value="Banner Poster">Banner Poster</option>
                    <option value="By Friend">By Friend</option>
                    <option value="By School">By School</option>
                    <option value="Others">Others</option>
                  </select>
                </div>
              </div>

              <div className="row py-5">
                <div className="col-6">
                  <p style={{ fontSize: "12px", color: "gray" }}>
                    Student Photo*
                  </p>
                  <input
                    className="AddBatchBox"
                    type="file"
                    accept="image/*"
                    onChange={handleStudentImage}
                  />
                </div>

                <div className="col-6">
                  <p style={{ fontSize: "12px", color: "gray" }}>
                    Student Signature*
                  </p>
                  <input
                    className="AddBatchBox"
                    type="file"
                    accept="image/*"
                    onChange={handleStudentSignature}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-6">
                  <p style={{ fontSize: "12px", color: "gray" }}>
                    Initial Fees*
                  </p>
                  <input
                    className="AddBatchBox"
                    type="number"
                    placeholder="Initial Fees*"
                    value={InitialFees}
                    onChange={handleInitialFees}
                  />
                </div>

                <div className="col-6">
                  <p style={{ fontSize: "12px", color: "gray" }}>
                    Registration Fees*
                  </p>
                  <input
                    className="AddBatchBox"
                    type="number"
                    placeholder="Registration Fees*"
                    value={RegistrationFees}
                    onChange={handleRegistrationFees}
                  />
                </div>
              </div>

              <div className="row py-5">
                <div className="col-6">
                  <p style={{ fontSize: "12px", color: "gray" }}>Status*</p>
                  <select
                    className="AddBatchBox"
                    value={Status}
                    onChange={handleStatus}
                  >
                    <option value="Active">Active</option>
                    <option value="InActive">InActive</option>
                  </select>
                </div>
              </div>
            </div>

            <button
              className="btn btn-primary btn-lg"
              style={{
                float: "right",
                marginTop: "50px",
                marginBottom: "20px",
              }}
              onClick={AddStudent}
            >
              Add Enquiry&nbsp;<i className="fa-solid fa-angles-right"></i>
            </button>
          </div>
        )}

        <table
          className={`table table-striped table-hover ${
            !isPopupOpen ? "" : "hidden"
          }`}
        >
          <thead
            style={{
              position: "sticky",
              top: 0,
              zIndex: 1,
              backgroundColor: "white",
            }}
          >
            <tr>
              <th scope="col">Regd.No</th>
              <th scope="col">Name</th>
              <th scope="col">Date_of_Birth</th>
              <th scope="col">Course Name</th>
              <th scope="col">Gender</th>
              <th scope="col">Phone Number1</th>
              <th scope="col">Phone Number2</th>
              <th scope="col">Email</th>
              <th scope="col">Aadhar Number</th>
              <th scope="col">Father's Name</th>
              <th scope="col">Mother's Name</th>
              <th scope="col">Marital Status</th>
              <th scope="col">Batch</th>
              <th scope="col">Address</th>
              <th scope="col">Pin Code</th>
              <th scope="col">State</th>
              <th scope="col">City</th>
              <th scope="col">Qualification</th>
              <th scope="col">School/College Name</th>
              <th scope="col">Board/University Name</th>
              <th scope="col">Reference</th>
              <th scope="col">Student Image</th>
              <th scope="col">Student Signature</th>
              <th scope="col">Initial Fees</th>
              <th scope="col">Registration Fees</th>
              <th scope="col">Status</th>
              <th scope="col">Date_of_Registration</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {StudentData.map((student, index) => (
              <tr key={student._id}>
                <td>{student.RegdNo}</td>
                <td>{student.Name}</td>
                <td>{student.DateOfBirth}</td>
                <td>{student.CourseName}</td>
                <td>{student.Gender}</td>
                <td>{student.PhoneNumber1}</td>
                <td>{student.PhoneNumber2}</td>
                <td>{student.Email}</td>
                <td>{student.AadharNo}</td>
                <td>{student.FatherName}</td>
                <td>{student.MotherName}</td>
                <td>{student.MaritalStatus}</td>
                <td>{student.Batch}</td>
                <td>{student.Address}</td>
                <td>{student.ZipCode}</td>
                <td>{student.State}</td>
                <td>{student.City}</td>
                <td>{student.Qualification}</td>
                <td>{student.SchoolCollegeName}</td>
                <td>{student.BoardUniversityName}</td>
                <td>{student.Reference}</td>
                <td>
                  <img
                    src={student.StudentImage}
                    alt="Student"
                    style={{ width: "100px" }}
                  />
                </td>
                <td>
                  <img
                    src={student.StudentSignature}
                    alt="Student"
                    style={{ width: "100px" }}
                  />
                </td>
                <td>{student.InitialFees}</td>
                <td>{student.RegistrationFees}</td>
                <td>{student.Status}</td>
                <td>{student.DateOfRegistration}</td>
                <td>
                  <i className="fa-solid fa-pencil"></i> &nbsp;
                  <i
                    className="fa-solid fa-trash"
                    onClick={() =>
                      DeleteStudent(
                        student.Name,
                        student.FatherName,
                        student.PhoneNumber1
                      )
                    }
                    style={{ cursor: "pointer" }}
                  ></i>
                  &nbsp;
                  <i className="fa-solid fa-user-graduate"></i>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Student;
