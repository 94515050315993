import React from "react";
import Reviewer1 from "../../Images/testimonials-1.jpg";
import Reviewer2 from "../../Images/testimonials-2.jpg";
import Reviewer3 from "../../Images/testimonials-3.jpg";

function HeroReviews() {
  return (
    <section
      className="text-center bg-light testimonials"
      style={{ marginTop: "-67px", marginBottom: "-89px" }}
    >
      <div className="container">
        <h2 className="mb-5">What people are saying...</h2>
        <div className="row">
          <div className="col-lg-4">
            <div className="mx-auto testimonial-item mb-5 mb-lg-0">
              <img
                alt="Sanya Arora"
                className="rounded-circle img-fluid mb-3"
                src={Reviewer1}
              />
              <h5>Sanya Arora</h5>
              <p className="font-weight-light mb-0">
                Amazing environment for studying. The teachers are friendly and
                experienced Learned a lot from here. This place will always be
                my get go for learning computer and English. Must recommended
              </p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="mx-auto testimonial-item mb-5 mb-lg-0">
              <img
                alt="Mayank Prajapati"
                className="rounded-circle img-fluid mb-3"
                src={Reviewer2}
              />
              <h5>Mayank Prajapati</h5>
              <p className="font-weight-light mb-0">
                I had a fantastic experience at the Cambridge Computer Education
                and English learning Centre. The staff was friendly and
                approachable, and they provided personalized attention to each
                student. The computer courses were comprehensive, and the
                English classes helped me improve my communication skills
                significantly. I'm grateful for the valuable learning
                environment they provided.😊😊
              </p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="mx-auto testimonial-item mb-5 mb-lg-0">
              <img
                alt="Khushi Arora"
                className="rounded-circle img-fluid mb-3"
                src={Reviewer3}
              />
              <h5>Khushi Arora</h5>
              <p className="font-weight-light mb-0">
                Enrolling in the computer and English course here was one of the
                best decisions I've made. The instructors were experts in their
                fields and made the learning process enjoyable. The institute
                also had state-of-the-art computer labs and resources that
                enhanced my learning experience. Thanks to their comprehensive
                curriculum, I feel equipped to tackle any computer-related task
                and communicate effectively in English.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroReviews;
