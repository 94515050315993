import React, { useState, useEffect } from "react";
const BASE_URL = process.env.REACT_APP_BASE_LINK;

function OldStudent() {
  const [StudentData, setStudentData] = useState([]);

  const fetchStudents = () => {
    fetch(BASE_URL + "getinactivestudents", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data.existingStudents)) {
          setStudentData(data.existingStudents); // Store the batch data in state
        } else {
          console.error("Error featching the data. Please try again!");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const DeleteStudent = (Name, FatherName, PhoneNumber) => {
    const shouldDelete = window.confirm(
      "Are you sure you want to delete this student?"
    );

    if (!shouldDelete) {
      return; // User clicked on "Cancel" or closed the dialog
    }

    const requestBody = {
      Name,
      FatherName,
      PhoneNumber,
    };

    fetch(BASE_URL + "deletestudent", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the response data
        if (data["message"]) {
          alert(data["message"]);
          fetchStudents();
        } else {
          alert("No response from the server. Please try again!");
          fetchStudents();
        }
        // Perform any necessary actions based on the response
      })
      .catch((error) => {
        // Handle any errors and print them to the console
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    fetchStudents();
  }, []);

  return (
    <div>
      <div
        style={{
          border: "1px solid black",
          overflow: "auto",
          width: "115%",
          maxHeight: "90vh",
        }}
      >
        <table className={`table table-striped table-hover`}>
          <thead
            style={{
              position: "sticky",
              top: 0,
              zIndex: 1,
              backgroundColor: "white",
            }}
          >
            <tr>
              <th scope="col">Regd.No</th>
              <th scope="col">Name</th>
              <th scope="col">Date_of_Birth</th>
              <th scope="col">Course Name</th>
              <th scope="col">Gender</th>
              <th scope="col">Phone Number1</th>
              <th scope="col">Phone Number2</th>
              <th scope="col">Email</th>
              <th scope="col">Aadhar Number</th>
              <th scope="col">Father's Name</th>
              <th scope="col">Mother's Name</th>
              <th scope="col">Marital Status</th>
              <th scope="col">Batch</th>
              <th scope="col">Address</th>
              <th scope="col">Pin Code</th>
              <th scope="col">State</th>
              <th scope="col">City</th>
              <th scope="col">Qualification</th>
              <th scope="col">School/College Name</th>
              <th scope="col">Board/University Name</th>
              <th scope="col">Reference</th>
              <th scope="col">Student Image</th>
              <th scope="col">Student Signature</th>
              <th scope="col">Initial Fees</th>
              <th scope="col">Registration Fees</th>
              <th scope="col">Status</th>
              <th scope="col">Date_of_Registration</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {StudentData.map((student, index) => (
              <tr key={student._id}>
                <td>{index + 1}</td>
                <td>{student.Name}</td>
                <td>{student.DateOfBirth}</td>
                <td>{student.CourseName}</td>
                <td>{student.Gender}</td>
                <td>{student.PhoneNumber1}</td>
                <td>{student.PhoneNumber2}</td>
                <td>{student.Email}</td>
                <td>{student.AadharNo}</td>
                <td>{student.FatherName}</td>
                <td>{student.MotherName}</td>
                <td>{student.MaritalStatus}</td>
                <td>{student.Batch}</td>
                <td>{student.Address}</td>
                <td>{student.ZipCode}</td>
                <td>{student.State}</td>
                <td>{student.City}</td>
                <td>{student.Qualification}</td>
                <td>{student.SchoolCollegeName}</td>
                <td>{student.BoardUniversityName}</td>
                <td>{student.Reference}</td>
                <td>
                  <img
                    src={student.StudentImage}
                    alt="Student"
                    style={{ width: "100px" }}
                  />
                </td>
                <td>
                  <img
                    src={student.StudentSignature}
                    alt="Student"
                    style={{ width: "100px" }}
                  />
                </td>
                <td>{student.InitialFees}</td>
                <td>{student.RegistrationFees}</td>
                <td>{student.Status}</td>
                <td>{student.DateOfRegistration}</td>
                <td>
                  <i className="fa-solid fa-pencil"></i> &nbsp;
                  <i
                    className="fa-solid fa-trash"
                    onClick={() =>
                      DeleteStudent(
                        student.Name,
                        student.FatherName,
                        student.PhoneNumber1
                      )
                    }
                    style={{ cursor: "pointer" }}
                  ></i>
                  &nbsp;
                  <i className="fa-solid fa-user-graduate"></i>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default OldStudent;
