import React from "react";

function HeroMiniFeatures() {
  return (
    <section className="text-center bg-light features-icons">
      <div className="container">
        <div
          className="row"
          style={{ marginBottom: "-68px", marginTop: "-70px" }}
        >
          <div className="col-lg-3">
            <div className="mx-auto features-icons-item mb-5 mb-lg-0 mb-lg-3">
              <div className="d-flex features-icons-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="-96 0 512 512"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  className="text-primary m-auto"
                  style={{ fontSize: "79px" }}
                >
                  <path d="M.0022 64C.0022 46.33 14.33 32 32 32H288C305.7 32 320 46.33 320 64C320 81.67 305.7 96 288 96H231.8C241.4 110.4 248.5 126.6 252.4 144H288C305.7 144 320 158.3 320 176C320 193.7 305.7 208 288 208H252.4C239.2 266.3 190.5 311.2 130.3 318.9L274.6 421.1C288.1 432.2 292.3 452.2 282 466.6C271.8 480.1 251.8 484.3 237.4 474L13.4 314C2.083 305.1-2.716 291.5 1.529 278.2C5.774 264.1 18.09 256 32 256H112C144.8 256 173 236.3 185.3 208H32C14.33 208 .0022 193.7 .0022 176C.0022 158.3 14.33 144 32 144H185.3C173 115.7 144.8 96 112 96H32C14.33 96 .0022 81.67 .0022 64V64z"></path>
                </svg>
              </div>
              <h3>Affordability</h3>
              <p className="lead mb-0">Quality education made affordable.</p>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="mx-auto features-icons-item mb-5 mb-lg-0 mb-lg-3">
              <div className="d-flex features-icons-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 -64 640 640"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  className="m-auto text-primary"
                  data-bss-hover-animate="pulse"
                  style={{ fontSize: "93px" }}
                >
                  <path d="M224 256c70.7 0 128-57.31 128-128S294.7 0 224 0C153.3 0 96 57.31 96 128S153.3 256 224 256zM274.7 304H173.3c-95.73 0-173.3 77.6-173.3 173.3C0 496.5 15.52 512 34.66 512H413.3C432.5 512 448 496.5 448 477.3C448 381.6 370.4 304 274.7 304zM479.1 320h-73.85C451.2 357.7 480 414.1 480 477.3C480 490.1 476.2 501.9 470 512h138C625.7 512 640 497.6 640 479.1C640 391.6 568.4 320 479.1 320zM432 256C493.9 256 544 205.9 544 144S493.9 32 432 32c-25.11 0-48.04 8.555-66.72 22.51C376.8 76.63 384 101.4 384 128c0 35.52-11.93 68.14-31.59 94.71C372.7 243.2 400.8 256 432 256z"></path>
                </svg>
              </div>
              <h3>Personal Mentoring</h3>
              <p className="lead mb-0">
                Unleash your potential with personalized mentoring that empowers
                you.
              </p>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="mx-auto features-icons-item mb-5 mb-lg-0 mb-lg-3">
              <div className="d-flex features-icons-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  className="m-auto text-primary"
                  data-bss-hover-animate="pulse"
                  style={{ fontSize: "78px" }}
                >
                  <path d="M45.63 79.75L52 81.25v58.5C45 143.9 40 151.3 40 160c0 8.375 4.625 15.38 11.12 19.75L35.5 242C33.75 248.9 37.63 256 43.13 256h41.75c5.5 0 9.375-7.125 7.625-13.1L76.88 179.8C83.38 175.4 88 168.4 88 160c0-8.75-5-16.12-12-20.25V87.13L128 99.63l.001 60.37c0 70.75 57.25 128 128 128s127.1-57.25 127.1-128L384 99.62l82.25-19.87c18.25-4.375 18.25-27 0-31.5l-190.4-46c-13-3-26.62-3-39.63 0l-190.6 46C27.5 52.63 27.5 75.38 45.63 79.75zM359.2 312.8l-103.2 103.2l-103.2-103.2c-69.93 22.3-120.8 87.2-120.8 164.5C32 496.5 47.53 512 66.67 512h378.7C464.5 512 480 496.5 480 477.3C480 400 429.1 335.1 359.2 312.8z"></path>
                </svg>
              </div>
              <h3>Expert Mentors</h3>
              <p className="lead mb-0">
                Elevate your journey with expert mentors who ignite
                transformation and empower your growth.
              </p>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="mx-auto features-icons-item mb-5 mb-lg-0 mb-lg-3">
              <div className="d-flex features-icons-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="-32 0 512 512"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  className="m-auto text-primary"
                  data-bss-hover-animate="pulse"
                  style={{ fontSize: "78px" }}
                >
                  <path d="M448 336v-288C448 21.49 426.5 0 400 0H96C42.98 0 0 42.98 0 96v320c0 53.02 42.98 96 96 96h320c17.67 0 32-14.33 32-31.1c0-11.72-6.607-21.52-16-27.1v-81.36C441.8 362.8 448 350.2 448 336zM143.1 128h192C344.8 128 352 135.2 352 144C352 152.8 344.8 160 336 160H143.1C135.2 160 128 152.8 128 144C128 135.2 135.2 128 143.1 128zM143.1 192h192C344.8 192 352 199.2 352 208C352 216.8 344.8 224 336 224H143.1C135.2 224 128 216.8 128 208C128 199.2 135.2 192 143.1 192zM384 448H96c-17.67 0-32-14.33-32-32c0-17.67 14.33-32 32-32h288V448z"></path>
                </svg>
              </div>
              <h3>Customized Study Material</h3>
              <p className="lead mb-0">
                Empower your success with customized study materials tailored
                just for you.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroMiniFeatures;
