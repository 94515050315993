import React, { useState, useEffect } from "react";
import StateOptions from "./AdminComponents/StateOptions";
import CityOptions from "./AdminComponents/CityOptions";
const BASE_URL = process.env.REACT_APP_BASE_LINK;

function Enquiry({ handleAdmission }) {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [Name, setName] = useState("");
  const [DateOfBirth, setDateOfBirth] = useState("");
  const [CourseName, setCourseName] = useState("");
  const [Gender, setGender] = useState("Male");
  const [PhoneNumber, setPhoneNumber] = useState("");
  const [Email, setEmail] = useState("");
  const [FatherName, setFatherName] = useState("");
  const [MotherName, setMotherName] = useState("");
  const [Address, setAddress] = useState("");
  const [ZipCode, setZipCode] = useState("");
  const [FollowUpDate, setFollowUpDate] = useState("");
  const [Qualification, setQualification] = useState("1 to 5");
  const [State, setState] = useState("Delhi");
  const [City, setCity] = useState("New Delhi");
  const [Reference, setReference] = useState("Digital Marketing");
  const [Remarks, setRemarks] = useState("");
  const [Status, setStatus] = useState("Active");
  const [enquiryData, setEnquiryData] = useState([]);

  const handleName = (e) => {
    setName(e.target.value);
  };

  const handleDateOfBirth = (e) => {
    setDateOfBirth(e.target.value);
  };

  const handleCourseName = (e) => {
    setCourseName(e.target.value);
  };

  const handleGender = (e) => {
    setGender(e.target.value);
  };

  const handlePhoneNumber = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleFatherName = (e) => {
    setFatherName(e.target.value);
  };

  const handleMotherName = (e) => {
    setMotherName(e.target.value);
  };

  const handleAddress = (e) => {
    setAddress(e.target.value);
  };

  const handleZipCode = (e) => {
    setZipCode(e.target.value);
  };

  const handleFollowUpDate = (e) => {
    setFollowUpDate(e.target.value);
  };

  const handleQualification = (e) => {
    setQualification(e.target.value);
  };

  const handleStateChange = (selectedState) => {
    setState(selectedState);
  };

  const handleCityChange = (e) => {
    setCity(e.target.value);
  };

  const handleReference = (e) => {
    setReference(e.target.value);
  };

  const handleRemarks = (e) => {
    setRemarks(e.target.value);
  };

  const handleStatus = (e) => {
    setStatus(e.target.value);
  };

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const feildValidator = () => {
    if (Name === "") {
      alert("Please Enter Name");
      return false;
    } else if (DateOfBirth === "") {
      alert("Please Enter Date Of Birth");
      return false;
    } else if (CourseName === "") {
      alert("Please Enter Course Name");
      return false;
    } else if (Gender === "") {
      alert("Please Enter Gender");
      return false;
    } else if (PhoneNumber === "") {
      alert("Please Enter Phone Number");
      return false;
    } else if (Email === "") {
      alert("Please Enter Email");
      return false;
    } else if (FatherName === "") {
      alert("Please Enter Father's Name");
      return false;
    } else if (MotherName === "") {
      alert("Please Enter Mother's Name");
      return false;
    } else if (Address === "") {
      alert("Please Enter Address");
      return false;
    } else if (ZipCode === "") {
      alert("Please Enter ZipCode");
      return false;
    } else if (Qualification === "") {
      alert("Please Enter Qualification");
      return false;
    } else if (FollowUpDate === "") {
      alert("Please Enter Follow Up Date");
      return false;
    } else if (State === "") {
      alert("Please Enter State");
      return false;
    } else if (City === "") {
      alert("Please Enter City");
      return false;
    } else if (Reference === "") {
      alert("Please Enter Reference");
      return false;
    } else if (Status === "") {
      alert("Please Enter Status");
      return false;
    } else {
      if (PhoneNumber.length < 10) {
        alert("Please Enter Valid Phone Number");
        return false;
      }
      return true;
    }
  };

  const clearAllData = () => {
    setName("");
    setDateOfBirth("");
    setCourseName("");
    setGender("Male");
    setPhoneNumber("");
    setEmail("");
    setFatherName("");
    setMotherName("");
    setAddress("");
    setZipCode("");
    setQualification("1 to 5");
    setFollowUpDate("");
    setState("Delhi");
    setCity("New Delhi");
    setReference("Digital Marketing");
    setRemarks("");
    setStatus("Active");
  };

  const fetchEnquiry = () => {
    fetch(BASE_URL + "getenquiry", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data.existingEnquiry)) {
          setEnquiryData(data.existingEnquiry); // Store the batch data in state
        } else {
          console.error("Error featching the data. Please try again!");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const AddEnquiry = () => {
    if (feildValidator()) {
      const requestBody = {
        Name: Name,
        DateOfBirth: DateOfBirth,
        CourseName: CourseName,
        Gender: Gender,
        PhoneNumber: PhoneNumber,
        Email: Email,
        FatherName: FatherName,
        MotherName: MotherName,
        Address: Address,
        ZipCode: ZipCode,
        Qualification: Qualification,
        FollowUpDate: FollowUpDate,
        State: State,
        City: City,
        Reference: Reference,
        Remarks: Remarks,
        Status: Status,
      };

      fetch(BASE_URL + "addenquiry", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      })
        .then((response) => response.json())
        .then((data) => {
          // Handle the response data
          if (data["message"] !== "Addition successful") {
            alert(data["message"]);
            fetchEnquiry();
          } else {
            alert("Addition Successful");
            togglePopup();
            clearAllData();
            fetchEnquiry();
          }
          // Perform any necessary actions based on the response
        })
        .catch((error) => {
          // Handle any errors and print them to the console
          console.error("Error:", error);
        });
    }
  };

  const DeleteStudent = (Name, FatherName, PhoneNumber) => {
    const shouldDelete = window.confirm(
      "Are you sure you want to delete this student?"
    );

    if (!shouldDelete) {
      return; // User clicked on "Cancel" or closed the dialog
    }

    const requestBody = {
      Name,
      FatherName,
      PhoneNumber,
    };

    fetch(BASE_URL + "deleteenquiry", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the response data
        if (data["message"]) {
          alert(data["message"]);
          fetchEnquiry();
        } else {
          alert("No response from the server. Please try again!");
          fetchEnquiry();
        }
        // Perform any necessary actions based on the response
      })
      .catch((error) => {
        // Handle any errors and print them to the console
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    fetchEnquiry();
  }, []);

  return (
    <div>
      <button
        type="button"
        className="btn btn-primary btn-lg"
        style={{
          borderRadius: "0%",
          paddingLeft: "25px",
          paddingRight: "25px",
          marginTop: "6px",
          marginBottom: "0.5px",
        }}
        onClick={togglePopup}
      >
        Add Enquiry
      </button>

      <div
        style={{
          border: "1px solid black",
          overflow: "auto",
          width: "115%",
          maxHeight: "90vh",
        }}
      >
        {isPopupOpen && (
          <div style={{ padding: "10px" }}>
            <i
              className="fa-solid fa-xmark"
              onClick={togglePopup}
              style={{ float: "right", fontSize: "22px", padding: "2px" }}
            ></i>
            <h3 style={{ textAlign: "center", paddingBottom: "20px" }}>
              Add Enquiry
            </h3>

            <div className="container">
              <div className="row py-5">
                <div className="col-6">
                  <p style={{ fontSize: "12px", color: "gray" }}>Name*</p>
                  <input
                    className="AddBatchBox"
                    type="text"
                    placeholder="Name*"
                    value={Name}
                    onChange={handleName}
                  />
                </div>

                <div className="col-6">
                  <p style={{ fontSize: "12px", color: "gray" }}>
                    Date Of Birth*
                  </p>
                  <input
                    className="AddBatchBox"
                    type="date"
                    placeholder="Date Of Birth*"
                    value={DateOfBirth}
                    onChange={handleDateOfBirth}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-6">
                  <p style={{ fontSize: "12px", color: "gray" }}>
                    Course Name*
                  </p>
                  <input
                    className="AddBatchBox"
                    type="text"
                    placeholder="Course Name*"
                    value={CourseName}
                    onChange={handleCourseName}
                  />
                </div>

                <div className="col-6">
                  <p style={{ fontSize: "12px", color: "gray" }}>Gender*</p>
                  <select
                    className="AddBatchBox"
                    value={Gender}
                    onChange={handleGender}
                  >
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
              </div>

              <div className="row py-5">
                <div className="col-6">
                  <p style={{ fontSize: "12px", color: "gray" }}>
                    Phone Number*
                  </p>
                  <input
                    className="AddBatchBox"
                    type="number"
                    placeholder="Phone Number*"
                    value={PhoneNumber}
                    onChange={handlePhoneNumber}
                  />
                </div>

                <div className="col-6">
                  <p style={{ fontSize: "12px", color: "gray" }}>Email*</p>
                  <input
                    className="AddBatchBox"
                    type="mail"
                    placeholder="Email*"
                    value={Email}
                    onChange={handleEmail}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-6">
                  <p style={{ fontSize: "12px", color: "gray" }}>
                    Father's Name*
                  </p>
                  <input
                    className="AddBatchBox"
                    type="text"
                    placeholder="Father's Name*"
                    value={FatherName}
                    onChange={handleFatherName}
                  />
                </div>

                <div className="col-6">
                  <p style={{ fontSize: "12px", color: "gray" }}>
                    Mother's Name*
                  </p>
                  <input
                    className="AddBatchBox"
                    type="text"
                    placeholder="Mother's Name*"
                    value={MotherName}
                    onChange={handleMotherName}
                  />
                </div>
              </div>

              <div className="row py-5">
                <div className="col-6">
                  <p style={{ fontSize: "12px", color: "gray" }}>Address*</p>
                  <input
                    className="AddBatchBox"
                    type="text"
                    placeholder="Addresse*"
                    value={Address}
                    onChange={handleAddress}
                  />
                </div>

                <div className="col-6">
                  <p style={{ fontSize: "12px", color: "gray" }}>Zip Code*</p>
                  <input
                    className="AddBatchBox"
                    type="number"
                    placeholder="Zip Code*"
                    value={ZipCode}
                    onChange={handleZipCode}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-6">
                  <p style={{ fontSize: "12px", color: "gray" }}>
                    Follow Up Date*
                  </p>
                  <input
                    className="AddBatchBox"
                    type="date"
                    placeholder="Follow Up Date*"
                    value={FollowUpDate}
                    onChange={handleFollowUpDate}
                  />
                </div>

                <div className="col-6">
                  <p style={{ fontSize: "12px", color: "gray" }}>
                    Qualification*
                  </p>
                  <select
                    className="AddBatchBox"
                    value={Qualification}
                    onChange={handleQualification}
                  >
                    <option value="1 to 5">1 to 5</option>
                    <option value="6 to 10">6 to 10</option>
                    <option value="11 to 12">11 to 12</option>
                    <option value="Graduation">Graduation</option>
                    <option value="Post Graduation">Post Graduation</option>
                  </select>
                </div>
              </div>

              <div className="row py-5">
                <div className="col-6">
                  <p style={{ fontSize: "12px", color: "gray" }}>State*</p>
                  <StateOptions
                    State={State}
                    handleStateChange={handleStateChange}
                  />
                </div>

                <div className="col-6">
                  <p style={{ fontSize: "12px", color: "gray" }}>City*</p>
                  <CityOptions
                    City={City}
                    handleCityChange={handleCityChange}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-6">
                  <p style={{ fontSize: "12px", color: "gray" }}>Reference*</p>
                  <select
                    className="AddBatchBox"
                    value={Reference}
                    onChange={handleReference}
                  >
                    <option value="Digital Marketing">Digital Marketing</option>
                    <option value="Banner Poster">Banner Poster</option>
                    <option value="By Friend">By Friend</option>
                    <option value="By School">By School</option>
                    <option value="Others">Others</option>
                  </select>
                </div>

                <div className="col-6">
                  <p style={{ fontSize: "12px", color: "gray" }}>Status*</p>
                  <select
                    className="AddBatchBox"
                    value={Status}
                    onChange={handleStatus}
                  >
                    <option value="Active">Active</option>
                    <option value="InActive">InActive</option>
                  </select>
                </div>
              </div>

              <div className="row py-5">
                <div className="col-6">
                  <p style={{ fontSize: "12px", color: "gray" }}>Remarks*</p>
                  <textarea
                    className="AddBatchBox"
                    rows="3"
                    placeholder="Remarks"
                    value={Remarks}
                    onChange={handleRemarks}
                  />
                </div>
              </div>
            </div>

            <button
              className="btn btn-primary btn-lg"
              style={{
                float: "right",
                marginTop: "50px",
                marginBottom: "20px",
              }}
              onClick={AddEnquiry}
            >
              Add Enquiry&nbsp;<i className="fa-solid fa-angles-right"></i>
            </button>
          </div>
        )}

        <table
          className={`table table-striped table-hover ${
            !isPopupOpen ? "" : "hidden"
          }`}
        >
          <thead
            style={{
              position: "sticky",
              top: 0,
              zIndex: 1,
              backgroundColor: "white",
            }}
          >
            <tr>
              <th scope="col">S.No</th>
              <th scope="col">Course</th>
              <th scope="col">Student Name</th>
              <th scope="col">Mobile Number</th>
              <th scope="col">Email</th>
              <th scope="col">Qualification</th>
              <th scope="col">Address</th>
              <th scope="col">Follow Up Date</th>
              <th scope="col">Remarks</th>
              <th scope="col">Status</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {enquiryData.map((enquiry, index) => (
              <tr key={enquiry._id}>
                <td>{index + 1}</td>
                <td>{enquiry.CourseName}</td>
                <td>{enquiry.Name}</td>
                <td>{enquiry.PhoneNumber}</td>
                <td>{enquiry.Email}</td>
                <td>{enquiry.Qualification}</td>
                <td>{enquiry.Address}</td>
                <td>{enquiry.FollowUpDate}</td>
                <td>{enquiry.Remarks}</td>
                <td>{enquiry.Status}</td>
                <td>
                  <i className="fa-solid fa-pencil"></i> &nbsp;
                  <i
                    className="fa-solid fa-trash"
                    onClick={() =>
                      DeleteStudent(
                        enquiry.Name,
                        enquiry.FatherName,
                        enquiry.PhoneNumber
                      )
                    }
                    style={{ cursor: "pointer" }}
                  ></i>
                  &nbsp;
                  <i
                    className="fa-solid fa-user-graduate"
                    onClick={() => handleAdmission(enquiry)}
                    style={{ cursor: "pointer" }}
                  ></i>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Enquiry;
