import React from "react";
import { Link } from "react-router-dom";

function Navbar(props) {
  return (
    <div>
      <nav className="navbar navbar-expand bg-light navigation-clean navbar-light border border-secondary">
        <div className="container">
          <button
            data-bs-toggle="collapse"
            className="navbar-toggler"
            data-bs-target="#navcol-1"
          ></button>
          <a className="navbar-brand" href="#">
            Cambridge Computer <span className="break" /> Education And English{" "}
            <span className="break" /> Learning Centre
          </a>
          <Link to="/login">
            <div className="collapse navbar-collapse" id="navcol-1">
              <span className="btn btn-primary ms-auto" role="button" href="#">
                Sign In
              </span>
            </div>
          </Link>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
