import React from "react";
import CambridgeComputerEducationFront from "../../Images/CambridgeComputerEducationFront.jpg";
import { useRequestCallback } from "./useRequestCallback";

function HeroFrontSection() {
  const { Phone, handlePhoneChange, RequestCallback } = useRequestCallback();

  return (
    <header
      className="text-center text-white masthead"
      style={{
        backgroundImage: `url(${CambridgeComputerEducationFront})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
      }}
    >
      <div className="overlay" style={{ opacity: 0.4 }}></div>
      <div className="container">
        <div className="row">
          <div className="col-xl-9 mx-auto position-relative">
            <h1 className="mb-5">
              Nurturing Curiosity,
              <br />
              Fostering Excellence
            </h1>
          </div>
          <div className="col-md-10 col-lg-8 col-xl-7 mx-auto position-relative">
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="row">
                <div className="col-12 col-md-9 mb-2 mb-md-0">
                  <input
                    className="form-control form-control-lg"
                    type="tel"
                    placeholder="Enter your Phone Number..."
                    style={{
                      marginTop: "15px",
                      paddingBottom: "0px",
                      paddingTop: "0px",
                    }}
                    value={Phone}
                    onChange={handlePhoneChange}
                  />
                </div>
                <div className="col">
                  <button
                    className="btn btn-primary btn-lg"
                    type="submit"
                    onClick={RequestCallback}
                  >
                    Request Callback
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </header>
  );
}

export default HeroFrontSection;
