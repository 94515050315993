import "./App.css";
import { HashRouter, Routes, Route } from "react-router-dom";
import Landing from "./Components/Landing_Page";
import Login from "./Components/Login/Login";
import Register from "./Components/Register/Register";
import Admin from "./Components/Admin/Admin";
import Student from "./Components/Student/Student";
import Side from "./sidebar";

function App() {
  return (
    <HashRouter>
      <div className="App">
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/student" element={<Student />} />
          <Route path="/side" element={<Side />} />
        </Routes>
      </div>
    </HashRouter>
  );
}

export default App;
