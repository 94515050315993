import React, { useState, forwardRef, useImperativeHandle } from "react";
import { useNavigate } from "react-router-dom";

const AdminSidebar = forwardRef((props, ref) => {
  const { onItemClick } = props;
  const navigate = useNavigate();
  const data = props.Data;
  const [activeItem, setActiveItem] = useState("Dashboard");
  const [collapsed, setCollapsed] = useState(false);

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  const handleClick = (itemName) => {
    setActiveItem(itemName);
    if (onItemClick) {
      onItemClick(itemName);
    }
  };

  const HandleLogOut = () => {
    localStorage.removeItem("isAdminLoggedIn");
    navigate("/");
  };

  useImperativeHandle(ref, () => ({
    handleClick: handleClick,
  }));

  return (
    <div style={{ height: "100vh" }}>
      <div
        className="d-flex flex-column flex-shrink-0 p-3 text-white bg-dark"
        style={{ height: "100%", overflow: "auto" }}
      >
        <a
          href="/"
          className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none"
        >
          <span className="fs-6">
            Cambridge Computer Education And English Learning Centre
          </span>
        </a>
        <hr />
        <ul className="nav nav-pills flex-column mb-auto">
          <li onClick={() => handleClick("Dashboard")}>
            <a
              className={`nav-link text-white ${
                activeItem === "Dashboard" ? "active" : ""
              }`}
            >
              <i className="fa-solid fa-house"></i>
              &nbsp; Dashboard
            </a>
          </li>

          <li onClick={() => handleClick("Batch")}>
            <a
              className={`nav-link text-white ${
                activeItem === "Batch" ? "active" : ""
              }`}
            >
              <i className="fa-solid fa-chalkboard-user"></i>
              &nbsp; Batch
            </a>
          </li>

          <li onClick={() => handleClick("Enquiry")}>
            <a
              className={`nav-link text-white ${
                activeItem === "Enquiry" ? "active" : ""
              }`}
            >
              <i className="fa-solid fa-user-gear"></i>
              &nbsp; Enquiry
            </a>
          </li>

          <li onClick={() => handleClick("Student")}>
            <a
              className={`nav-link text-white ${
                activeItem === "Student" ? "active" : ""
              }`}
            >
              <i className="fa-solid fa-user-graduate"></i>
              &nbsp; Student
            </a>
          </li>

          <li onClick={() => handleClick("Old Student")}>
            <a
              className={`nav-link text-white ${
                activeItem === "Old Student" ? "active" : ""
              }`}
            >
              <i className="fa-solid fa-person-circle-xmark"></i>
              &nbsp; Old Student
            </a>
          </li>

          <li onClick={() => handleClick("Attendance")}>
            <a
              className={`nav-link text-white ${
                activeItem === "Attendance" ? "active" : ""
              }`}
            >
              <i className="fa-solid fa-building-user"></i>
              &nbsp; Attendance
            </a>
          </li>

          <li onClick={() => handleClick("Fees")}>
            <a
              className={`nav-link text-white ${
                activeItem === "Fees" ? "active" : ""
              }`}
            >
              <i className="fa-solid fa-money-bill"></i>
              &nbsp; Fees
            </a>
          </li>

          <li onClick={() => handleClick("Admit Card")}>
            <a
              className={`nav-link text-white ${
                activeItem === "Admit Card" ? "active" : ""
              }`}
            >
              <i className="fa-solid fa-id-card"></i>
              &nbsp; Admit Card
            </a>
          </li>

          <li onClick={() => handleClick("Certificate & Marksheet")}>
            <a
              className={`nav-link text-white ${
                activeItem === "Certificate & Marksheet" ? "active" : ""
              }`}
            >
              <i className="fa-solid fa-file"></i>
              &nbsp; Certificate & Marksheet
            </a>
          </li>

          <li onClick={() => handleClick("Report")}>
            <a
              className={`nav-link text-white ${
                activeItem === "Report" ? "active" : ""
              }`}
            >
              <i className="fa-solid fa-file-invoice-dollar"></i>
              &nbsp; Report
            </a>
          </li>

          <li onClick={() => handleClick("Study Material")}>
            <a
              className={`nav-link text-white ${
                activeItem === "Study Material" ? "active" : ""
              }`}
            >
              <i className="fa-solid fa-book"></i>
              &nbsp; Study Material
            </a>
          </li>

          <li onClick={() => handleClick("Syllabus")}>
            <a
              className={`nav-link text-white ${
                activeItem === "Syllabus" ? "active" : ""
              }`}
            >
              <i className="fa-solid fa-book"></i>
              &nbsp; Syllabus
            </a>
          </li>

          <li onClick={() => handleClick("Expense")}>
            <a
              className={`nav-link text-white ${
                activeItem === "Expense" ? "active" : ""
              }`}
            >
              <i className="fa-solid fa-money-bill-transfer"></i>
              &nbsp; Expense
            </a>
          </li>

          <li onClick={() => handleClick("Income")}>
            <a
              className={`nav-link text-white ${
                activeItem === "Income" ? "active" : ""
              }`}
            >
              <i className="fa-solid fa-building-columns"></i>
              &nbsp; Income
            </a>
          </li>
        </ul>
        <hr />
        <div className="dropdown">
          <a
            className="d-flex align-items-center text-white text-decoration-none dropdown-toggle"
            id="dropdownUser1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <strong>{data["Name"]}</strong>
          </a>
          <ul
            className="dropdown-menu dropdown-menu-dark text-small shadow"
            aria-labelledby="dropdownUser1"
          >
            <li>
              <a className="dropdown-item" onClick={HandleLogOut}>
                Sign out
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
});

export default AdminSidebar;
