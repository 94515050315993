import React from "react";
import IndividualTeacher from "../../Images/IndividualTeacher.jpg";
import CodeBg from "../../Images/bg-showcase-2.jpg";
import TeacherTeaching from "../../Images/TeacherTeaching.jpg";

function HeroFeatureShowcase() {
  return (
    <section className="showcase">
      <div className="container-fluid p-0">
        <div className="row g-0">
          <div
            className="col-lg-6 text-white order-lg-2 showcase-img"
            style={{ backgroundImage: `url(${IndividualTeacher})` }}
          >
            <span></span>
          </div>
          <div className="col-lg-6 my-auto order-lg-1 showcase-text">
            <h2>Individualized Learning Paths</h2>
            <p className="lead mb-0">
              Embrace tailored learning designed just for you. We recognize your
              uniqueness, crafting personalized paths aligned with your
              strengths and goals. Our expert educators create a custom
              curriculum, ensuring you excel at your pace. Say farewell to
              generic education; welcome a unique learning journey shaped around
              you.
            </p>
          </div>
        </div>
        <div className="row g-0">
          <div
            className="col-lg-6 text-white showcase-img"
            style={{ backgroundImage: `url(${CodeBg})` }}
          >
            <span></span>
          </div>
          <div className="col-lg-6 my-auto order-lg-1 showcase-text">
            <h2>Cutting-Edge Tech Learning</h2>
            <p className="lead mb-0">
              Experience education at the forefront of technology! Our institute
              provides hands-on training in the latest software and hardware,
              ensuring you stay ahead in the digital age. From coding workshops
              to virtual reality labs, immerse yourself in a world where
              innovation meets education. Prepare to be future-ready with our
              cutting-edge tech learning programs.
            </p>
          </div>
        </div>
        <div className="row g-0">
          <div
            className="col-lg-6 text-white order-lg-2 showcase-img"
            style={{ backgroundImage: `url(${TeacherTeaching})` }}
          >
            <span></span>
          </div>
          <div className="col-lg-6 my-auto order-lg-1 showcase-text">
            <h2>Global Language Proficiency</h2>
            <p className="lead mb-0">
              Master language, seize the world! Our English Language Learning
              program extends beyond textbooks, emphasizing vital practical
              communication skills for today's global landscape. Through
              immersive lessons, interactive debates, and cultural exchanges, we
              empower you to confidently navigate English-speaking environments.
              Become a skilled communicator, unlocking endless global
              opportunities.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroFeatureShowcase;
