import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBIcon,
} from "mdb-react-ui-kit";
const BASE_URL = process.env.REACT_APP_BASE_LINK;

function Login() {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  function handleUsernameChange(e) {
    setUsername(e.target.value);
  }
  function handlePasswordChange(e) {
    setPassword(e.target.value);
  }

  function authenticator() {
    document.querySelector(".loading-indicator").style.display = "block";
    // Create a request body object with the username and password
    const requestBody = {
      Email: username,
      Password: password,
    };

    // Make a POST request to the server
    fetch(BASE_URL + "login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => response.json())
      .then((data) => {
        document.querySelector(".loading-indicator").style.display = "none";
        // Handle the response data
        if (data["message"] !== "Login successful") {
          alert(data["message"]);
        } else {
          if (data["user"]["Admin"]) {
            localStorage.setItem("isAdminLoggedIn", true);
            navigate("/admin", {
              state: { user: data["user"], fromRoute: true },
            });
          } else if (data["user"]["Student"]) {
            localStorage.setItem("isStudentLoggedIn", true);
            navigate("/student", {
              state: { user: data["user"], fromRoute: true },
            });
          } else {
            localStorage.setItem("isLoggedIn", false);
            navigate("/");
          }
        }
      })
      .catch((error) => {
        document.querySelector(".loading-indicator").style.display = "none";
        alert("Error: Can't connect to server, please try again later!");
      });
  }

  function handleKeyDown(event) {
    if (event.key === "Enter") {
      authenticator();
    }
  }

  return (
    <div>
      <div className="loading-indicator"></div>
      {localStorage.removeItem("isAdminLoggedIn")}
      <MDBContainer fluid>
        <MDBCard className="text-black m-5" style={{ borderRadius: "25px" }}>
          <MDBCardBody className="mt-5 mb-5">
            <Link to="/">
              <i
                className="fa-solid fa-arrow-left fa-lg"
                style={{ color: "Black" }}
              ></i>
            </Link>
            <MDBRow className="mt-5 mb-5">
              <MDBCol
                md="10"
                lg="6"
                className="order-2 order-lg-1 d-flex flex-column align-items-center"
              >
                <p className="text-center h1 fw-bold mb-5 mx-1 mx-md-4 mt-4">
                  Log In
                </p>
                <div className="d-flex flex-row align-items-center mb-4">
                  <MDBIcon fas icon="envelope me-3" size="lg" />
                  <input
                    type="email"
                    id="email"
                    className="form-control"
                    placeholder="Your Email"
                    value={username}
                    onChange={handleUsernameChange}
                    onKeyDown={handleKeyDown}
                  />
                </div>
                <div className="d-flex flex-row align-items-center mb-4">
                  <MDBIcon fas icon="key me-3" size="lg" />
                  <input
                    type="password"
                    id="password"
                    className="form-control"
                    placeholder="Enter your password"
                    value={password}
                    onChange={handlePasswordChange}
                    onKeyDown={handleKeyDown}
                  />
                </div>
                <div className="mb-4">
                  <Link to="/register">
                    <p className="text-decoration-none">New Here?</p>
                  </Link>
                </div>
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={authenticator}
                >
                  Login
                </button>
              </MDBCol>
              <MDBCol
                md="10"
                lg="6"
                className="order-1 order-lg-2 d-flex align-items-center"
              >
                <MDBCardImage
                  src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-registration/draw1.webp"
                  fluid
                />
              </MDBCol>
            </MDBRow>
          </MDBCardBody>
        </MDBCard>
      </MDBContainer>
    </div>
  );
}

export default Login;
