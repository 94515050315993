import React from "react";
import HeroFrontSection from "./HeroFrontSection";
import HeroMiniFeatures from "./HeroMiniFeatures";
import HeroFeatureShowcase from "./HeroFeatureShowcase";
import HeroReviews from "./HeroReviews";
import HeroCallForAction from "./HeroCallForAction";
import Courses from "../Courses/Courses.jsx";

function HeroSection() {
  return (
    <div>
      <HeroFrontSection />
      <HeroMiniFeatures />
      <HeroFeatureShowcase />
      <HeroReviews />
      <Courses />
      <HeroCallForAction />
    </div>
  );
}

export default HeroSection;
