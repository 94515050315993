import React from "react";
import Banner from "../../Images/CambridgeBanner.jpg";
import { useRequestCallback } from "./useRequestCallback";

function HeroCallForAction() {
  const { Phone, handlePhoneChange, RequestCallback } = useRequestCallback();

  return (
    <section
      className="text-center text-white call-to-action"
      style={{
        backgroundImage: `url(${Banner})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
      }}
    >
      <div className="overlay" style={{ opacity: "0.75" }}></div>
      <div className="container">
        <div className="row">
          <div className="col-xl-9 mx-auto position-relative">
            <h2 className="mb-4">
              Ready to get started? Request A Call Back Now!
            </h2>
          </div>
          <div className="col-md-10 col-lg-8 col-xl-7 mx-auto position-relative">
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="row">
                <div className="col-12 col-md-9 mb-2 mb-md-0">
                  <input
                    className="form-control form-control-lg"
                    type="tel"
                    placeholder="Enter your Phone Number..."
                    style={{ marginTop: "13px" }}
                    value={Phone}
                    onChange={handlePhoneChange}
                  />
                </div>
                <div className="col-12 col-md-3">
                  <button
                    className="btn btn-primary btn-lg"
                    type="submit"
                    onClick={RequestCallback}
                  >
                    Request Callback
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroCallForAction;
