import React, { useEffect, useState, useRef  } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AdminSidebar from './AdminSidebar';
import Dashboard from './Dashboard';
import Batch from './Batch';
import Enquiry from './Enquiry';
import Student from './Student';
import OldStudent from './OldStudent';
import Attendance from './Attendance';
import Fees from './Fees';
import AdmitCard from './AdmitCard';
import CertificateMarksheet from './CertificateMarksheet';
import Report from './Report';
import StudyMaterial from './StudyMaterial';
import Syllabus from './Syllabus';
import Expense from './Expense';
import Income from './Income';

function Admin() {
  const childRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const isLoadedFromRoute = location.state && location.state.fromRoute && localStorage.getItem('isAdminLoggedIn');
  const [data, setData] = useState(null);
  const [category, setCategory] = useState('Dashboard');
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [enquiryData, setEnquiryData] = useState(null);

  const handleSidebarItemClick = (itemName) => {
    setCategory(itemName);
    setSidebarOpen(false);
  };

  const handleSidebarToggle = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleAdmission = (enquiry) => {
    if (childRef.current) {
      childRef.current.handleClick('Student');
    }
    setCategory('Student');    
    setSidebarOpen(false);
    setEnquiryData(enquiry);    
  };

  function MainContent() {
    switch (category) {
      case 'Dashboard':
        return <Dashboard />;
      case 'Batch':
        return <Batch />;
      case 'Enquiry':
        return <Enquiry handleAdmission={handleAdmission}/>;
      case 'Student':
        return <Student enquiryData={enquiryData}/>;
      case 'Old Student':
        return <OldStudent />;
      case 'Attendance':
        return <Attendance />;
      case 'Fees':
        return <Fees />;
      case 'Admit Card':
        return <AdmitCard />;
      case 'Certificate & Marksheet':
        return <CertificateMarksheet />;
      case 'Report':
        return <Report />;
      case 'Study Material':
        return <StudyMaterial />;
      case 'Syllabus':
        return <Syllabus />;
      case 'Expense':
        return <Expense />;
      case 'Income':
        return <Income />;
      default:
        return <Dashboard />;
    }
  }

  useEffect(() => {
    if (!isLoadedFromRoute) {
      navigate('/login');
    } else {
      setData(location.state.user);
    }
  }, [isLoadedFromRoute, navigate, location.state]);

  return (
    <div className="container" style={{ marginLeft: "0px", paddingLeft: "0px"}}>
      <div className="row">
        <div className={`col-md-3 ${sidebarOpen ? '' : 'd-none d-md-block'}`}>
          {data && <AdminSidebar ref={childRef} Data={data} onItemClick={handleSidebarItemClick} />}
        </div>
        <div className={`col-md-9 ${!sidebarOpen ? '' : 'd-none d-md-block'}`}>
          <div>
            <i className="fa-solid fa-bars d-md-none" onClick={handleSidebarToggle}></i>
            {MainContent()}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Admin;