import React, { useState } from "react";

const Sidebar = () => {
  const [collapsed, setCollapsed] = useState(false);

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div style={{ height: "100vh" }}>
      <div
        className="d-flex flex-column flex-shrink-0 p-3 text-white bg-dark"
        style={{ width: "280px", height: "100%", overflow: "auto" }}
      >
        <a
          href="/"
          className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none"
        >
          <span className="fs-6">
            Cambridge Computer Education And English Learning Centre
          </span>
        </a>
        <hr />
        <ul className="nav nav-pills flex-column mb-auto">
          <li className="nav-item">
            <a className="nav-link active" aria-current="page">
              <i class="fa-solid fa-house"></i>
              &nbsp; Dashboard
            </a>
          </li>

          <li>
            <a className="nav-link text-white">
              <i class="fa-solid fa-chalkboard-user"></i>
              &nbsp; Batch
            </a>
          </li>

          <li>
            <a className="nav-link text-white">
              <i class="fa-solid fa-user-gear"></i>
              &nbsp; Enquiry
            </a>
          </li>

          <li>
            <a className="nav-link text-white">
              <i class="fa-solid fa-user-graduate"></i>
              &nbsp; Student
            </a>
          </li>

          <li>
            <a className="nav-link text-white">
              <i class="fa-solid fa-person-circle-xmark"></i>
              &nbsp; Old Student
            </a>
          </li>

          <li>
            <a className="nav-link text-white">
              <i class="fa-solid fa-building-user"></i>
              &nbsp; Attendance
            </a>
          </li>

          <li>
            <a className="nav-link text-white">
              <i class="fa-solid fa-money-bill"></i>
              &nbsp; Fees
            </a>
          </li>

          <li>
            <a className="nav-link text-white">
              <i class="fa-solid fa-id-card"></i>
              &nbsp; Admit Card
            </a>
          </li>

          <li>
            <a className="nav-link text-white">
              <i class="fa-solid fa-file"></i>
              &nbsp; Certificate & Marksheet
            </a>
          </li>

          <li>
            <a className="nav-link text-white">
              <i class="fa-solid fa-file-invoice-dollar"></i>
              &nbsp; Report
            </a>
          </li>

          <li>
            <a className="nav-link text-white">
              <i class="fa-solid fa-book"></i>
              &nbsp; Study Material
            </a>
          </li>

          <li>
            <a className="nav-link text-white">
              <i class="fa-solid fa-book"></i>
              &nbsp; Syllabus
            </a>
          </li>

          <li>
            <a className="nav-link text-white">
              <i class="fa-solid fa-money-bill-transfer"></i>
              &nbsp; Expense
            </a>
          </li>

          <li>
            <a className="nav-link text-white">
              <i class="fa-solid fa-building-columns"></i>
              &nbsp; Income
            </a>
          </li>
        </ul>
        <hr />
        <div className="dropdown">
          <a
            className="d-flex align-items-center text-white text-decoration-none dropdown-toggle"
            id="dropdownUser1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <strong>mdo</strong>
          </a>
          <ul
            className="dropdown-menu dropdown-menu-dark text-small shadow"
            aria-labelledby="dropdownUser1"
          >
            <li>
              <a className="dropdown-item">Sign out</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
