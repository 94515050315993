import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBIcon,
} from "mdb-react-ui-kit";
const BASE_URL = process.env.REACT_APP_BASE_LINK;

function Register() {
  const navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const [userMail, setUserMail] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [userPassword, setUserPassword] = useState("");

  function handleName(e) {
    setUserName(e.target.value);
  }

  function handleEmail(e) {
    setUserMail(e.target.value);
  }

  function handlePhone(e) {
    setUserPhone(e.target.value);
  }

  function handlePassword(e) {
    setUserPassword(e.target.value);
  }

  function register() {
    document.querySelector(".loading-indicator").style.display = "block";
    // Create a request body object with the username and password
    const requestBody = {
      Name: userName,
      Email: userMail,
      PhoneNumber: userPhone,
      Password: userPassword,
    };
    // Make a POST request to the server
    fetch(BASE_URL + "register", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => response.json())
      .then((data) => {
        document.querySelector(".loading-indicator").style.display = "none";
        // Handle the response data
        if (data["message"] !== "Registration successful") {
          alert(data["message"]);
        } else {
          alert("Registration Successful");
          navigate("/login");
        }
        // Perform any necessary actions based on the response
      })
      .catch((error) => {
        document.querySelector(".loading-indicator").style.display = "none";
        // Handle any errors and print them to the console
        console.error("Error:", error);
      });
  }

  function handleKeyDown(event) {
    if (event.key === "Enter") {
      register();
    }
  }

  return (
    <div>
      <div className="loading-indicator"></div>
      <MDBContainer fluid>
        <MDBCard className="text-black m-5" style={{ borderRadius: "25px" }}>
          <MDBCardBody className="mt-5 mb-5">
            <Link to="/">
              <i
                className="fa-solid fa-arrow-left fa-lg"
                style={{ color: "Black" }}
              ></i>
            </Link>
            <MDBRow className="mt-5 mb-5">
              <MDBCol
                md="10"
                lg="6"
                className="order-2 order-lg-1 d-flex flex-column align-items-center"
              >
                <p className="text-center h1 fw-bold mb-5 mx-1 mx-md-4 mt-4">
                  Sign up
                </p>
                <div className="d-flex flex-row align-items-center mb-4 ">
                  <MDBIcon fas icon="user me-3" size="lg" />
                  <input
                    type="text"
                    id="name"
                    className="form-control"
                    placeholder="Your Name"
                    value={userName}
                    onChange={handleName}
                    onKeyDown={handleKeyDown}
                  />
                </div>
                <div className="d-flex flex-row align-items-center mb-4">
                  <MDBIcon fas icon="envelope me-3" size="lg" />
                  <input
                    type="email"
                    id="email"
                    className="form-control"
                    placeholder="Your Email"
                    value={userMail}
                    onChange={handleEmail}
                    onKeyDown={handleKeyDown}
                  />
                </div>
                <div className="d-flex flex-row align-items-center mb-4">
                  <MDBIcon fas icon="phone me-3" size="lg" />
                  <input
                    type="tel"
                    id="phone"
                    className="form-control"
                    placeholder="Your Phone Number"
                    value={userPhone}
                    onChange={handlePhone}
                    onKeyDown={handleKeyDown}
                  />
                </div>
                <div className="d-flex flex-row align-items-center mb-4">
                  <MDBIcon fas icon="key me-3" size="lg" />
                  <input
                    type="password"
                    id="password"
                    className="form-control"
                    placeholder="Password"
                    value={userPassword}
                    onChange={handlePassword}
                    onKeyDown={handleKeyDown}
                  />
                </div>
                <div className="mb-4">
                  <Link to="/login">
                    <p className="text-decoration-none"> Already A User?</p>
                  </Link>
                </div>
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={register}
                >
                  Register
                </button>
              </MDBCol>
              <MDBCol
                md="10"
                lg="6"
                className="order-1 order-lg-2 d-flex align-items-center"
              >
                <MDBCardImage
                  src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-registration/draw1.webp"
                  fluid
                />
              </MDBCol>
            </MDBRow>
          </MDBCardBody>
        </MDBCard>
      </MDBContainer>
    </div>
  );
}

export default Register;
