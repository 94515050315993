import { useState } from "react";
const BASE_URL = process.env.REACT_APP_BASE_LINK;

export const useRequestCallback = () => {
  const [Phone, setPhone] = useState("");

  function handlePhoneChange(e) {
    setPhone(e.target.value);
  }

  function RequestCallback(event) {
    event.preventDefault();
    const pattern = /[^+\d]/g;
    if (Phone.length < 10 || Phone.length > 14) {
      alert("Please enter a valid phone number!");
      return;
    }

    if (pattern.test(Phone)) {
      alert("Phone number should contain only digits and the plus sign (+).");
      return;
    }

    const requestBody = { PhoneNumber: Phone };

    fetch(BASE_URL + "requestcallback", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(requestBody),
    })
      .then((response) => response.json())
      .then((data) => alert(data["message"]))
      .catch((error) =>
        alert("Error: Can't connect to server, please try again later!")
      );
  }

  return { Phone, handlePhoneChange, RequestCallback };
};
