import React from "react";

function Footer() {
  return (
    <footer className="bg-light footer">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 text-center text-lg-start my-auto h-100">
            <ul className="list-inline mb-2">
              <li className="list-inline-item">
                <a href="#">About</a>
              </li>
              <li className="list-inline-item">
                <span>⋅</span>
              </li>
              <li className="list-inline-item">
                <a href="#">Contact</a>
              </li>
              <li className="list-inline-item">
                <span>⋅</span>
              </li>
              <li className="list-inline-item">
                <a href="#">Terms of &nbsp;Use</a>
              </li>
              <li className="list-inline-item">
                <span>⋅</span>
              </li>
              <li className="list-inline-item">
                <a href="#">Privacy Policy</a>
              </li>
            </ul>
            <p className="text-muted small mb-4 mb-lg-0">
              © Cambridge Computer Education And English Learning Centre 2023.
              All Rights Reserved.
            </p>
          </div>
          <div className="col-lg-6 text-center text-lg-end my-auto h-100">
            <ul className="list-inline mb-0">
              <li className="list-inline-item">
                <a href="#">
                  <i className="fa fa-facebook fa-2x fa-fw"></i>
                </a>
              </li>
              <li className="list-inline-item">
                <a href="https://g.co/kgs/63gAHW">
                  <i className="fa-brands fa-google fa-2x fa-fw"></i>
                </a>
              </li>
              <li className="list-inline-item">
                <a href="https://www.instagram.com/cambridge_computer_education/">
                  <i className="fa fa-instagram fa-2x fa-fw"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
