import React, { useState, useEffect } from "react";
const BASE_URL = process.env.REACT_APP_BASE_LINK;

function Batch() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [CourseName, setCourseName] = useState("");
  const [BatchCode, setBatchCode] = useState("");
  const [BatchName, setBatchName] = useState("");
  const [BatchStartTime, setBatchStartTime] = useState("");
  const [BatchEndTime, setBatchEndTime] = useState("");
  const [BatchStartDate, setBatchStartDate] = useState("");
  const [BatchEndDate, setBatchEndDate] = useState("");
  const [BatchStatus, setBatchStatus] = useState("Active");
  const [batchData, setBatchData] = useState([]);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const handleCourseName = (e) => {
    setCourseName(e.target.value);
  };

  const handleBatchCode = (e) => {
    setBatchCode(e.target.value);
  };

  const handleBatchName = (e) => {
    setBatchName(e.target.value);
  };

  const handleBatchStartTime = (e) => {
    setBatchStartTime(e.target.value);
  };

  const handleBatchEndTime = (e) => {
    setBatchEndTime(e.target.value);
  };

  const handleBatchStartDate = (e) => {
    setBatchStartDate(e.target.value);
  };

  const handleBatchEndDate = (e) => {
    setBatchEndDate(e.target.value);
  };

  const handleBatchStatus = (e) => {
    setBatchStatus(e.target.value);
  };

  const clearAllData = () => {
    setCourseName("");
    setBatchCode("");
    setBatchName("");
    setBatchStartTime("");
    setBatchEndTime("");
    setBatchStartDate("");
    setBatchEndDate("");
    setBatchStatus("Active");
  };

  const convertTo24HourFormat = (timeString) => {
    const [time, period] = timeString.split(" ");
    let [hours, minutes] = time.split(":");

    hours = parseInt(hours, 10);

    if (period === "PM" && hours !== 12) {
      hours += 12;
    } else if (period === "AM" && hours === 12) {
      hours = 0;
    }

    return `${hours.toString().padStart(2, "0")}:${minutes}`;
  };

  const fieldValidator = (e) => {
    if (CourseName === "") {
      alert("Please enter Course Name");
      return false;
    } else if (BatchCode === "") {
      alert("Please enter Batch Code");
      return false;
    } else if (BatchName === "") {
      alert("Please enter Batch Name");
      return false;
    } else if (BatchStartTime === "") {
      alert("Please enter Batch Start Time");
      return false;
    } else if (BatchEndTime === "") {
      alert("Please enter Batch End Time");
      return false;
    } else if (BatchStartDate === "") {
      alert("Please enter Batch Start Date");
      return false;
    } else if (BatchEndDate === "") {
      alert("Please enter Batch End Date");
      return false;
    } else if (BatchStatus === "") {
      alert("Please enter Batch Status");
      return false;
    } else {
      const startTime24Hour = convertTo24HourFormat(BatchStartTime);
      const endTime24Hour = convertTo24HourFormat(BatchEndTime);
      const startDate = new Date(BatchStartDate);
      const endDate = new Date(BatchEndDate);

      const startTime = new Date(`1970-01-01T${startTime24Hour}`);
      const endTime = new Date(`1970-01-01T${endTime24Hour}`);

      if (startTime >= endTime) {
        alert("Start Time should be before End Time");
        return false;
      }

      if (startDate >= endDate) {
        alert("Start Date should be before End Date");
        return false;
      }

      return true;
    }
  };

  const AddBatch = () => {
    if (fieldValidator()) {
      // Create a request body object with the username and password
      const requestBody = {
        CourseName: CourseName,
        BatchCode: BatchCode,
        BatchName: BatchName,
        BatchStartTime: BatchStartTime,
        BatchEndTime: BatchEndTime,
        BatchStartDate: BatchStartDate,
        BatchEndDate: BatchEndDate,
        BatchStatus: BatchStatus,
      };
      // Make a POST request to the server
      fetch(BASE_URL + "addbatch", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      })
        .then((response) => response.json())
        .then((data) => {
          // Handle the response data
          if (data["message"] !== "Addition successful") {
            alert(data["message"]);
            fetchBatchData();
          } else {
            alert("Addition Successful");
            togglePopup();
            clearAllData();
            fetchBatchData();
          }
          // Perform any necessary actions based on the response
        })
        .catch((error) => {
          // Handle any errors and print them to the console
          console.error("Error:", error);
        });
    }
  };

  const fetchBatchData = () => {
    fetch(BASE_URL + "getbatches", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data.existingBatch)) {
          setBatchData(data.existingBatch); // Store the batch data in state
        } else {
          console.error("Error featching the data. Please try again!");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  // useEffect hook to fetch batch data on component mount
  useEffect(() => {
    fetchBatchData();
  }, []);

  return (
    <div>
      <button
        type="button"
        className="btn btn-primary btn-lg"
        style={{
          borderRadius: "0%",
          paddingLeft: "25px",
          paddingRight: "25px",
          marginTop: "6px",
          marginBottom: "0.5px",
        }}
        onClick={togglePopup}
      >
        Add Batch
      </button>

      <div
        style={{
          border: "1px solid black",
          overflow: "auto",
          width: "115%",
          maxHeight: "90vh",
        }}
      >
        {isPopupOpen && (
          <div style={{ padding: "10px" }}>
            <i
              className="fa-solid fa-xmark"
              onClick={togglePopup}
              style={{ float: "right", fontSize: "22px", padding: "2px" }}
            ></i>
            <h3 style={{ textAlign: "center", paddingBottom: "20px" }}>
              Add Batch
            </h3>

            <div className="container">
              <div className="row py-5">
                <div className="col-6">
                  <p style={{ fontSize: "12px", color: "gray" }}>
                    Enter Course Name*
                  </p>
                  <input
                    className="AddBatchBox"
                    type="text"
                    placeholder="Course Name*"
                    value={CourseName}
                    onChange={handleCourseName}
                  />
                </div>

                <div className="col-6">
                  <p style={{ fontSize: "12px", color: "gray" }}>
                    Enter Batch Code*
                  </p>
                  <input
                    className="AddBatchBox"
                    type="text"
                    placeholder="Batch Code*"
                    value={BatchCode}
                    onChange={handleBatchCode}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-6">
                  <p style={{ fontSize: "12px", color: "gray" }}>
                    Enter Batch Name*
                  </p>
                  <input
                    className="AddBatchBox"
                    type="text"
                    placeholder="Batch Name*"
                    value={BatchName}
                    onChange={handleBatchName}
                  />
                </div>

                <div className="col-6">
                  <p style={{ fontSize: "12px", color: "gray" }}>
                    Enter Timing From*
                  </p>
                  <input
                    className="AddBatchBox"
                    type="time"
                    placeholder="Timing From*"
                    value={BatchStartTime}
                    onChange={handleBatchStartTime}
                  />
                </div>
              </div>

              <div className="row py-5">
                <div className="col-6">
                  <p style={{ fontSize: "12px", color: "gray" }}>
                    Enter Timing To*
                  </p>
                  <input
                    className="AddBatchBox"
                    type="time"
                    placeholder="Timing To*"
                    value={BatchEndTime}
                    onChange={handleBatchEndTime}
                  />
                </div>

                <div className="col-6">
                  <p style={{ fontSize: "12px", color: "gray" }}>
                    Enter Starting Date*
                  </p>
                  <input
                    className="AddBatchBox"
                    type="date"
                    placeholder="Starting Date*"
                    value={BatchStartDate}
                    onChange={handleBatchStartDate}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-6">
                  <p style={{ fontSize: "12px", color: "gray" }}>
                    Enter Ending Date*
                  </p>
                  <input
                    className="AddBatchBox"
                    type="date"
                    placeholder="Ending Date*"
                    value={BatchEndDate}
                    onChange={handleBatchEndDate}
                  />
                </div>

                <div className="col-6">
                  <p style={{ fontSize: "12px", color: "gray" }}>
                    Enter Status*
                  </p>
                  <input
                    className="AddBatchBox"
                    type="text"
                    placeholder="Status*"
                    value={BatchStatus}
                    onChange={handleBatchStatus}
                  />
                </div>
              </div>
            </div>

            <button
              className="btn btn-primary btn-lg"
              style={{
                float: "right",
                marginTop: "50px",
                marginBottom: "20px",
              }}
              onClick={AddBatch}
            >
              Add Batch&nbsp;<i className="fa-solid fa-angles-right"></i>
            </button>
          </div>
        )}

        <table
          className={`table table-striped table-hover ${
            !isPopupOpen ? "" : "hidden"
          }`}
        >
          <thead
            style={{
              position: "sticky",
              top: 0,
              zIndex: 1,
              backgroundColor: "white",
            }}
          >
            <tr>
              <th scope="col">S.No</th>
              <th scope="col">Batch Code</th>
              <th scope="col">Batch Name</th>
              <th scope="col">Batch Start Timing</th>
              <th scope="col">Batch End Timing</th>
              <th scope="col">Batch Start Date</th>
              <th scope="col">Batch End Date</th>
              <th scope="col">Batch Status</th>
              <th scope="col">Batch Action</th>
            </tr>
          </thead>
          <tbody>
            {batchData.map((batch, index) => (
              <tr key={batch._id}>
                <td>{index + 1}</td>
                <td>{batch.BatchCode}</td>
                <td>{batch.BatchName}</td>
                <td>{batch.BatchStartTime}</td>
                <td>{batch.BatchEndTime}</td>
                <td>{batch.BatchStartDate}</td>
                <td>{batch.BatchEndDate}</td>
                <td>{batch.BatchStatus}</td>
                <td>
                  <i className="fa-solid fa-pencil"></i> &nbsp;{" "}
                  <i className="fa-solid fa-trash"></i>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Batch;
