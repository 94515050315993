import React from "react";
import Navbar from "./Navigation/Navbar";
import StaffTraining from "./Navigation/StaffTraining";
import HeroSection from "./HeroSection/HeroSection";
import Footer from "./Footer/Footer";

function Landing(props) {
  return (
    <div>
      <StaffTraining />
      <Navbar />
      <HeroSection />
      <Footer />
    </div>
  );
}

export default Landing;
