import React from "react";
import { Card, Row, Col } from "react-bootstrap";
import "./style.css";
import DCAImage from "../../Images/DCA.png";
import MSBasicImage from "../../Images/MSBasic.jpg";
import MSAdvanceImage from "../../Images/MSAdvance.png";
import EnlishImage from "../../Images/English.jpg";
import Class10Image from "../../Images/10thClass.jpg";
import Class12Image from "../../Images/12thClass.png";

const Courses = () => {
  const courseData = [
    {
      name: "DCA Course",
      details:
        "Diploma in Computer Applications (DCA) is designed to provide a comprehensive understanding of computer applications.",
      duration: "300+ hours",
      popularity: 4,
      image: DCAImage,
    },
    {
      name: "Microsoft Office Basic",
      details:
        "This course covers the basics of Microsoft Office applications, including Word, Excel, and PowerPoint.",
      duration: "150+ hours",
      popularity: 4,
      image: MSBasicImage,
    },
    {
      name: "Microsoft Office Advance",
      details:
        "This course covers advanced topics in Microsoft Office applications, including advanced features of Word, Excel, and PowerPoint.",
      duration: "150+ hours",
      popularity: 5,
      image: MSAdvanceImage,
    },
    {
      name: "English Speaking Course",
      details:
        "This course focuses on improving English speaking skills, including vocabulary, grammar, and pronunciation.",
      duration: "150+ hours",
      popularity: 5,
      image: EnlishImage,
    },
    {
      name: "1-10th Class Course",
      details:
        "This course covers the syllabus for classes 1 to 10, including subjects like Mathematics, Science, and English.",
      duration: "300+ hours",
      popularity: 4,
      image: Class10Image,
    },
    {
      name: "11-12 Class Course",
      details:
        "This course covers the syllabus for classes 11 and 12, including subjects like Physics, Chemistry, and Biology.",
      duration: "300+ hours",
      popularity: 5,
      image: Class12Image,
    },
  ];

  return (
    <div className="courses-container">
      <h1 className="section-title" style={{ textAlign: "center" }}>
        Browse Courses
      </h1>
      <Row className="mb-4">
        {courseData.map((course, index) => (
          <Col key={index} sm={6} md={4} lg={4}>
            <Card className="course-card">
              <div className="course-details">
                <h3>{course.name}</h3>
                <p>{course.details}</p>
                <p style={{ textAlign: "center" }}>
                  <i className="fa-solid fa-star"></i>: {course.popularity}{" "}
                  <i class="fa-solid fa-clock"></i>: {course.duration}
                </p>
              </div>
              <div className="course-image">
                <img src={course.image} alt={course.name} />
              </div>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Courses;
