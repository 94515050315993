import React from "react";

function Dashboard(){

    return(
        <div>
            Dashboard 123
        </div>
    )

}

export default Dashboard;