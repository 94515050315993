import React from "react";
import { useNavigate } from "react-router-dom";

function StaffTraining() {
  const navigate = useNavigate();
  function RedirectToLogin() {
    navigate("/login");
  }
  const MyComponent = () => {
    const element = document.querySelector(".StaffTraining");
    element.remove();
  };
  return (
    <div className="StaffTraining">
      <span>
        Explore Professional Training or Empower your Staff Through Tailored
        Programs.
      </span>
      <button
        className="btn btn-primary btn-sm lg-ms-5 ms-2"
        type="submit"
        onClick={RedirectToLogin}
      >
        Click Here
      </button>
      <i
        className="fa-solid fa-xmark float-end lg-me-5 mt-2"
        onClick={MyComponent}
      ></i>
    </div>
  );
}

export default StaffTraining;
